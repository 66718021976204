import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  sidekickHero,
  sidekickTrusted,
  sidekickWhiteGlove,
  sidekickLargeFeatures,
  sidekickVideo,
  sidekickSolutionsSection,
  sidekickCTA,
  sidekickSolutionsSlides,
} from "../../../data/product-pages/sidekick-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import { billySlides, heroSuccess } from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "sidekick-white-glove.png";
const whiteGloveImportMobile = "sidekick-wh-mobile.png";

const sidekick = () => {
  return (
    <Layout>
      <SEO
        title="SpotOn Sidekick| Food truck POS | Mobile POS"
        description="SpotOn Sidekick makes it easier than ever for on-the-go food businesses like food trucks to take orders, print tickets, process payments, and stay online just about anywhere."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={sidekickHero} circleBg={false} />
      <TrustedNumbers numbersArray={sidekickTrusted} />
      <WhiteGlove
        sectionData={sidekickWhiteGlove}
        whiteGloveBg={whiteGloveImport}
        whiteGloveBgMobile={whiteGloveImportMobile}
      />
      <LargeFeatures
        sectionData={sidekickLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <SuccessStories sectionData={heroSuccess} slides={billySlides} />
      <VideoSection sectionData={sidekickVideo} />
      <ProductLines
        sectionData={sidekickSolutionsSection}
        productSlides={sidekickSolutionsSlides}
      />
      <PartnerLogos />
      <LargeCta sectionData={sidekickCTA} />
    </Layout>
  );
};

export default sidekick;
