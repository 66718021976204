// eslint-disable-next-line import/prefer-default-export
export const sidekickHero = {
  title: "Portable POS for food trucks and more",
  subtext:
    "SpotOn Sidekick makes it easier than ever for mobile kitchens and on-the-go food businesses to take orders, print tickets, process payments, and stay online just about anywhere.",
  heroImg: "sidekick-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/sidekick/demo",
  },
  fatTitle: "Sidekick",
};

export const sidekickTrusted = [
  {
    title: "300m+",
    subtext: "Marketing campaigns sent using SpotOn",
  },
  {
    title: "5m+",
    subtext: "Redemptions with SpotOn services and growing",
  },
  {
    title: "67%",
    subtext: "Online ordering customers visit more often",
  },
  {
    title: "18%",
    subtext: "Increase in average spend with online orders",
  },
];

export const sidekickWhiteGlove = {
  mainTitle: "Expert support. Wherever your business takes you",
  title: "Get the personalized support you deserve",
  content:
    "Mobile kitchens aren’t like other restaurants, but you deserve the same white-glove service that big restaurants expect. Whether you’re running a food truck, taco stand, food cart, concession stand, or other on-the-go food businesses, SpotOn has you covered. We’ll work closely with you to optimize your menu for in-person and online sales and be there whenever you need us with personalized service and support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/sidekick/demo",
  },
};

export const sidekickLargeFeatures = {
  sectionTitle: "Boost efficiency, increase sales, and keep guests coming back",
  featureBlocks: [
    {
      blockTitle: "A smarter way to take orders & payments",
      blockImg: "sk-a.png",
      blockSubTitle:
        "Streamline how you take orders and payments with the all-in-one handheld POS built for mobile kitchens.",
      blockList: [
        "Orders funneled straight to your kitchen",
        "Custom menus for on-site and online ordering",
        "Intuitive on-screen menu navigation",
        "Integrated online ordering",
        "Customer email capture with integrated marketing",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/sidekick/demo",
      },
    },
    {
      blockTitle: "Everything you need in one package",
      blockImg: "sk-b.png",
      blockList: [
        "Stay online anywhere with Wi-Fi and 4G wireless connectivity",
        "Apple Pay, Google Pay, and NFC enabled",
        "Built-in receipt printer and scanner",
        "Integrated Bluetooth kitchen ticket printer",
        "Cash drawer",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/sidekick/demo",
      },
    },
    {
      blockTitle: "Commission-free online ordering",
      blockImg: "sk-c.png",
      blockSubTitle:
        "Cut your lines and increase sales by making it easy for your guests to order and pay right from their mobile phone. And unlike third-party apps, SpotOn never charges commissions, meaning you keep more money in your pocket.",
      blockList: [
        "Online ordering through your website and Facebook page",
        "QR code order & pay to shorten your lines",
        "Optional no-hassle local delivery",
        "Real-time updates to menu, hours, and options",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/sidekick/demo",
      },
    },
    {
      blockTitle: "Smart reporting",
      blockImg: "sk-d.png",
      blockSubTitle:
        "Get the data you need to maximize profits with detailed and easy-to-use reports on sales by hour, product mix, pricing specials, transactions, and more. Our cloud-based reporting makes it easy to see what’s selling, what’s not, and who your best customers are at a glance, from any computer or right from your phone with our mobile reporting app.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/sidekick/demo",
      },
    },
    {
      blockTitle: "Simple tools to connect with your customers",
      blockImg: "sk-e.png",
      blockSubTitle:
        "SpotOn Sidekick comes packed with powerful tools to help manage and grow your business. Send marketing emails right from your phone to let your customers know where you’re moving next. Manage your online reviews with real-time alerts to improve your online presence. And send deals to your customers via email and Facebook to drive repeat visits.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/sidekick/demo",
      },
    },
  ],
};

export const sidekickBusinessTypesCar = {
  title: "The payment terminal built for on-the-go restaurants",
  subtext:
    "SpotOn Sidekick is compact, flexible, and built specifically for mobile kitchens. Food trucks, pop-up restaurants, concession stands, taco stands, food carts. If you’re more “four wheels” than “four walls”, we’ve got you covered.",
  slides: [
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
  ],
};

export const sidekickVideo = {
  title: "When your restaurant is less “four walls” and more “four wheels”",
  videoBtn: "Watch video",
  posterWrapperClass: "",
  posterImg: "food-truck.png",
  videoUrlId: "CKYuJeylAWM",
};

export const sidekickSolutionsSection = {
  title: "Works seamlessly with other tools for growth",
  subText:
    "Instead of a tangled web of costly systems that are a nightmare to manage, SpotOn offers a simple, integrated solution with the tools you need to run and grow your mobile food business.",
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const sidekickTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const sidekickIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and sidekick processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept sidekicks and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const sidekickCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/sidekick/demo",
  },
};

export const sidekickFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const sidekickSolutionsSlides = [
  {
    title: "Online Ordering",
    link: "/products/online-order-and-delivery",
    img: "Online ordering.png",
  },
  {
    title: "Delivery Logistics",
    link: "/products/delivery",
    img: "Delivery.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
];
